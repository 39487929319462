<template>
  <div>
    <v-card :class="myTask.sanitizedArray && myTask.sanitizedArray.length > 0 ? '' : 'mb-3'" elevation="3" :to="customAction ? '' : `/task/${myTask._id}`" @click="handleClick">
      <v-card-title class="font-weight-regular">
        <v-row class="mb-0 pb-0">
          <v-col cols="12" sm="8">
            {{ myTask.name }}
          </v-col>
          <v-col v-if="showCreator" class="pt-0 text-right">
            <user-button :user="myTask.creator" />
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <p class="taskText">
          {{ myTask.text }}
        </p>
        <v-row>
          <v-col dense>
            <time-thingy v-if="showCreationDate" :time="myTask.created" text-before="created " text-after=" ago." />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions v-if="showCompletionsButton || showEditButton || myTask.protectedTask">
        <v-spacer />
        <v-icon v-if="myTask.protectedTask" class="pr-1 grey--text text--darken-2"> mdi-lock </v-icon>
        <v-btn v-if="showCompletionsButton && loadingDone" outlined :disabled="totalCount <= 0 || completionsReadOnly" color="primary" :to="`/completions/${myTask._id}`">
          {{ solutionsString }}
        </v-btn>
        <v-btn v-if="showEditButton" outlined fab x-small :color="protectedColor" @click.stop.prevent="toggleFeatured">
          <v-icon>mdi-star</v-icon>
        </v-btn>
        <v-btn v-if="showEditButton" outlined fab x-small color="primary" :to="`/edit-task/${myTask._id}`">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-expansion-panels v-if="myTask.sanitizedArray && myTask.sanitizedArray.length > 0" class="mb-3">
      <v-expansion-panel>
        <v-expansion-panel-header>... and {{ myTask.sanitizedArray.length }} more</v-expansion-panel-header>
        <v-expansion-panel-content>
          <div v-for="currentTask in myTask.sanitizedArray" :key="currentTask._id">
            <v-card :to="`/task/${currentTask._id}`">
              <v-card-title class="font-weight-regular">
                <v-row class="mb-0 pb-0">
                  <v-col>
                    {{ currentTask.name }}
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-text>
                {{ currentTask.text }}
                <time-thingy v-if="showCreationDate" :time="currentTask.created" text-before="created " text-after=" ago." />
              </v-card-text>
            </v-card>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import apiClient from '../../apiClient';
import UserButton from './UserButton.vue';
import TimeThingy from '../technical/TimeThingy.vue';

export default {
  name: 'TaskTeaser',
  components: {
    UserButton,
    TimeThingy,
  },
  props: {
    myTask: {
      type: Object,
      required: true,
    },
    showEditButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    listType: {
      type: String,
      required: true,
    },
    showCompletionsButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    completionsReadOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
    sanitationArray: {
      type: Array,
      required: false,
      default: () => [],
    },
    showCreator: {
      type: Boolean,
      required: false,
      default: false,
    },
    customAction: {
      type: Boolean,
      required: false,
      default: false,
    },
    totalTime: {
      type: Number,
      required: false,
      default: 0,
    },
    showCreationDate: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      totalCount: 0,
      loadingDone: false,
    };
  },
  computed: {
    solutionsString() {
      if (!this.showCompletionsButton || !this.loadingDone) {
        return '';
      }
      if (this.totalCount === 1) {
        return 'completed once';
      }
      if (this.totalCount === 0) {
        return 'not completed yet';
      }
      if (this.totalCount < 0) {
        return 'Completions hidden';
      }
      return `completed ${this.totalCount} times`;
    },
    protectedColor() {
      if (this.myTask.featured) {
        return 'success';
      }
      if (this.myTask.protectedTask) {
        return 'grey darken-1';
      }
      return 'primary';
    },
  },
  mounted() {
    if (this.showCompletionsButton) {
      // Get solutions
      let requestObject = {};
      let listType = 'taskSolutionCount';
      if (this.listType === 'myTasks') {
        listType = 'mySolutionCount';
      }
      requestObject = {
        limit: 1,
        listType,
        task: this.myTask._id,
      };
      apiClient
        .post('getSolutions', requestObject)
        .then((response) => {
          this.totalCount = response.data.totalCount;
          this.loadingDone = true;
        })
        .catch(() => {});
    }
  },
  methods: {
    toggleFeatured() {
      const requestObject = {
        taskID: this.myTask._id,
      };
      apiClient
        .post('taskToggleFeatured', requestObject)
        .then((response) => {
          this.myTask.featured = !this.myTask.featured;
          let snackText;
          if (response.data.featured) {
            snackText = 'This task is now featured on your profile.';
          } else {
            snackText = 'This task is no longer featured on your profile.';
          }
          this.$store.commit('setSnackbar', {
            show: true,
            text: snackText,
            color: 'success',
          });
          this.$emit('updateTaskList');
        })
        .catch(() => {
          this.$store.commit('setSnackbar', {
            show: true,
            text: 'Sorry, there was a problem changing the featured status of this task. Please try again later!',
            color: 'error',
          });
        });
    },
    handleClick() {
      if (this.customAction) {
        this.$emit('chooseTask', this.myTask);
      }
    },
  },
};
</script>

<style scoped>
p.taskText {
  font-size: 1.4em;
}
</style>
